<template>
  <v-container class="csv-upload">
    <v-img
        alt="create-sequence-image"
        class="shrink mr-2 logo-img create-sequence"
        contain
        src="@/assets/images/csv-upload-sequence.png"
        transition="scale-transition"
        width="834"
    />
    <v-file-input
        v-model="file"
        class="csv-input"
        label="CSVを選択"
        accept=".xlsx,.csv"
        truncate-length="15"
    ></v-file-input>
    <div class="upload-description">・アップロードできるファイルは、Microsoft Excel（.xlsx）、CSV（.csv）です。<br/>
      ・テンプレートは <a href="/">こちら</a> からダウンロードしてください。<br/>
      ・アップロード後、管理画面から商品の個別編集ができます</div>
    <v-btn
        width="260"
        height="52"
        color="#1867C0"
        class="white--text select-csv-button"
        elevation="2"
        to="/checkData"
        :disabled="!file"
    >選択したCSVをアップロード</v-btn>

  </v-container>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "CsvUpload",
  data () {
    return {
      file: null
    }
  },
}
</script>

<style lang="scss" scoped>
.csv-upload {
  .create-sequence {
    padding-top: 30px;
    padding-left: 30px;
  }
  .csv-input {
    padding-top: 70px;
    padding-left: 30px;
    width: 731px;
  }
  .upload-description {
    padding-top: 19px;
    padding-left: 54px;
  }
  .select-csv-button {
    margin-top: 45px;
    margin-left: 647px;
  }
}
</style>
