<template>
  <v-app>
    <v-app-bar
        app
        color="#D9D9D9"
        dark
        height="85"
    >
      <div class="d-flex align-center">
        <div id="header-logo">
          <v-img
              alt="Vuetify Logo"
              class="shrink mr-2 logo-img"
              contain
              src="@/assets/images/logo-toyo.png"
              transition="scale-transition"
              width="180"
          />
          <div>チラシ自動生成サービス</div>
        </div>
        <v-img
            alt="Vuetify Logo"
            class="shrink mr-2 header-city-img"
            contain
            src="@/assets/images/Icon-city.png"
            transition="scale-transition"
        />
        <div class="header-search">
          <v-select
              v-model="item_status"
              :items="shops"
              label=""
              dense
              outlined
          ></v-select>
        </div>
        <div class="header-user-icon">
          <v-img
              alt="Vuetify Logo"
              class="shrink mr-2 header-city-img"
              contain
              src="@/assets/images/default-user-icon.png"
              transition="scale-transition"
          />
        </div>
      </div>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-navigation-drawer permanent absolute color="black" width="220" class="side-menu">
      <v-list nav dense>
        <v-list-group
            v-for="nav_list in sideMenu"
            :key="nav_list.title"
            no-action
            :append-icon="nav_list.child ? undefined : ''"
            value="true"
            disabled
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="side-menu-title">{{ nav_list.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
              v-for="list in nav_list.child"
              :key="list.name"
              :to="list.link"
          >
            <v-list-item-content>
              <v-list-item-title class="side-menu-title">{{ list.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
<!--      画面のコンテンツ表示-->
      <RouterView/>
    </v-main>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',
  data: () => ({
    shops: ['三島店','沼津店', '静岡駅前店', '伊豆の国店', '掛川店'],
    item_status: '三島店',
    sideMenu: [
      {
        title: 'チラシ管理',
        child: [
          {title: 'チラシを作成', link: '/csvUpload'},
          {title: 'チラシ原稿一覧', link: '/'},
          {title: 'タグ設定'}
        ],
      },
      {
        title: 'お知らせ投稿'
      },
      {
        title: '店舗情報'
      },
    ],
    links: ['Home', 'Contacts', 'Settings'],
  }),
};
</script>

<style lang="scss">
#header-logo {
  margin: auto;
  width: 220px;
  height: 85px;
  background: white;

  .logo-img {
    margin-left: 10px;
    padding: 10px;
  }

  div {
    color: black;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
  }
}

.v-toolbar__content {
  padding-left: 0;
}

.header-city-img {
  padding-left: 60px;
}

.v-select {
  height: 40px;
  width: 35vw;
  padding-right: 200px;
  border-radius: 40px 40px 40px 40px;
  background-color: white;
}

.v-select__selection {
  color: #333333;
}

.theme--dark.v-icon {
  color: #333333 !important;
}

.header-user-icon {
  position: absolute;
  right: 10px;
}

.side-menu {
  padding-top: 90px;

  .side-menu-title {
    color: white;
  }

  .v-icon {
    display: none;
  }
}
.v-main {
  padding-left: 220px !important;
}
</style>
