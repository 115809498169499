import Vue from 'vue'
import Router from 'vue-router'

import Home from './components/Home.vue'
import CsvUpload from './components/CsvUpload.vue'
import CheckData from './components/CheckData.vue'
import DoneData from './components/DoneData.vue'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
        },
        {
            path: '/csvUpload',
            name: 'CsvUpload',
            component: CsvUpload,
        },
        {
            path: '/checkData',
            name: 'checkData',
            component: CheckData,
        },
        {
            path: '/doneData',
            name: 'doneData',
            component: DoneData,
        },
    ],
})
