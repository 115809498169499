<template>
  <v-container class="done-data">
    <v-img
        alt="done-data-sequence-image"
        class="shrink mr-2 logo-img done-data-sequence"
        contain
        src="@/assets/images/done-sequence.png"
        transition="scale-transition"
        width="834"
    />
    <div class="done-data-description">
      ・この画面では項目の編集はできません。編集する場合は「編集に戻る」から、「一覧を確認/編集」のステップへ戻ってください。<br/>
      ・チラシ内容を確定すると、公開画面に表示されるよになります。まだ公開しない場合は、「下書きとして保存」してください。
    </div>
    <v-data-table
        :headers="headers"
        :items="itemList"
        :items-per-page="10"
        class="elevation-1 data-table"
    >
      <template v-slot:[`item.tag`]="{ item }">
      <v-btn
          v-if="item.tag != ''"
          rounded>
        {{item.tag}}
      </v-btn>
    </template>
      <template v-slot:[`item.image`]="{ item }">
        <div class="image_area">
          <v-img
              contain
              width="58"
              height="42"
              class="image-inline"
              :src="`https://toyo-systemflyer.s3.ap-northeast-1.amazonaws.com/admin/image/${item.image}.png`"
          /><div class="image-inline image-text">{{item.image}}</div>
        </div>
      </template>
      <template v-slot:[`item.toyo`]>
        <v-btn
            width="100"
            height="30"
            color="#4CAF50"
            style="color: white; font-size: 10px;"
            rounded
            v-on:click="isSelectImageDialog">
          商品画像を確認
        </v-btn>
      </template>
    </v-data-table>
  <v-btn
      absolute
      left
      width="130"
      height="36"
      class="back-upload-button"
      elevation="2"
      to="/checkData"
  >編集に戻す</v-btn>
    <v-btn
        absolute
        right
        width="260"
        height="52"
        outlined
        color="indigo"
        class="draft-data"
        elevation="2"
        to="/?done=true&draft=true"
    >下書きとして保存</v-btn>
  <v-btn
      absolute
      right
      width="260"
      height="52"
      color="#1867C0"
      class="white--text submit-data"
      elevation="2"
      to="/?done=true"
  >チラシ内容を確定</v-btn>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "CheckData",
  created: function () {
    // CSVの取得と反映を行う
    axios.get('https://toyo-systemflyer.s3.ap-northeast-1.amazonaws.com/admin/csv/import_done_data.csv').then((response) => {
      this.itemList = this.$papa.parse(response.data, {
        header: true,
        complete: function(results) {
          return results.data
        },
      }).data;
    });
  },
  data () {
    return {
      headers: [
        { text: '開始日', value: 'start_date', width: '120px' },
        { text: '終了日', value: 'end_date',  width: '120px' },
        { text: 'カテゴリ', value: 'category', width: '150px' },
        { text: 'メーカー・産地', value: 'manufacturer', width: '150px' },
        { text: '商品名', value: 'name', width: '150px' },
        { text: '規格', value: 'standard', width: '150px' },
        { text: '売価', value: 'selling_price', width: '100px' },
        { text: '税込', value: 'tax', width: '100px' },
        { text: 'アイコン', value: 'icon', width: '100px' },
        { text: 'タグ', value: 'tag' ,width: '100px'},
        { text: '画像設定', value: 'image', width: '80px'},
      ],
      itemList: [],
    }
  },
}
</script>

<style lang="scss" scoped>
.done-data {
  .done-data-sequence {
    padding-top: 30px;
    padding-left: 30px;
  }
  .done-data-description {
    padding-top: 43px;
    padding-left: 46px;
    padding-bottom: 64px;
  }
  .back-upload-button {
    margin-top: 41px;
    margin-left: 54px;
  }
  .draft-data {
    margin-top: 45px;
    margin-right: 320px;
  }
  .submit-data {
    margin-top: 45px;
    margin-right: 48px;
  }
}
.image_area {
  width: 230px;
  height: 48px;
}
.image-inline {
  display: inline-block;
}
.image-text {
  padding-left: 10px;
  padding-top: 15px;
  vertical-align: top;
}
</style>
