<template>
  <v-container class="data-check">
    <v-img
        alt="create-sequence-image"
        class="shrink mr-2 logo-img check-sequence"
        contain
        src="@/assets/images/check-data-sequence.png"
        transition="scale-transition"
        width="834"
    />
    <div class="check-description">
      ・項目をクリックすると入力欄に切り替わり、原稿項目を修正できます。<br/>
      ・一覧の内容を保存後も、項目を修正することができます。
    </div>
    <v-data-table
        :headers="headers"
        :items="itemList"
        :items-per-page="10"
        class="elevation-1 data-table">
      <template v-slot:[`item.tag`]="{ item }">
        <v-btn
            v-if="item.tag != ''"
               rounded>
          {{item.tag}}
        </v-btn>
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <div class="image_area">
          <v-img
              contain
              width="58"
              height="42"
              class="image-inline"
              :src="`https://toyo-systemflyer.s3.ap-northeast-1.amazonaws.com/admin/image/${item.image}.png`"
          /><div class="image-inline image-text">{{item.image}}</div>
        </div>
      </template>
      <template v-slot:[`item.toyo`]>
        <v-btn
            width="100"
            height="30"
            color="#4CAF50"
            style="color: white; font-size: 10px;"
            rounded
        v-on:click="isSelectImageDialog">
          商品画像を確認
        </v-btn>
      </template>
    </v-data-table>
    <v-btn
        absolute
        left
        width="130"
        height="36"
        class="back-upload-button"
        elevation="2"
        to="/csvUpload"
    >CSVを選び直す</v-btn>
    <v-btn
        absolute
        right
        width="260"
        height="52"
        color="#1867C0"
        class="white--text done-data"
        elevation="2"
        to="/doneData"
    >一覧の内容を保存</v-btn>
    <v-dialog
      v-model="isSelectImageSetting"
      width="749">
      <v-card>
        <h2 class="dialog-title">
          商品画像を設定する
        </h2>
        <pre class="first-dialog-description-text">
          商品画像を、<span style="color: #1867C0;">東洋印刷DBからインポート</span> または <span style="color: #4CAF50;">お手持ちの画像をアップロード</span>
          することで設定ができます。
        </pre>
        <v-row style="padding: 50px 60px 107px;">
          <v-btn
              class="toyo-select-btn"
              color="#1867C0"
              style="width: 277px;height: 117px;color: white;font-weight: bold"
              @click="isToyoImageImportDialog"
          >東洋印刷DBから<br/>画像をインポートする</v-btn>
          <v-btn
              class="toyo-select-btn"
              color="#4CAF50"
              style="width: 277px;height: 117px;color: white;font-weight: bold;margin-left: 53px"
              @click="isSelectLocalImage"
          >パソコンから画像を<br/>アップロードする</v-btn>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isToyoImageImport"
      width="749">
      <v-card>
        <h2 class="dialog-title">
          東洋印刷DBから画像をインポートする
        </h2>
        <v-row class="second-dialog-search-area">
          <v-text-field
              label="キーワードから画像検索"
              class="second-dialog-text-search"
              onchange="updateField"
          ></v-text-field>
          <v-btn
              class="second-dialog-search-btn"
              color="#1867C0"
              v-on:click="search"
          >
            検索
          </v-btn>
        </v-row>
        <v-row class="second-dialog-image-area" v-if="searchDone">
          <v-col>
            <v-img
                class="toyo-image"
                v-bind:class="selectImageNo == 1 ? isSelect : ''"
                width="103"
                height="70"
                contain
                src="@/assets/images/mini-tomato1.png"
                @click="selectImage(1)"
            ></v-img>
            <span class="selectText" v-if="selectImageNo == 1">選択中</span>
          </v-col>
          <v-col>
            <v-img
                class="toyo-image"
                v-bind:class="selectImageNo == 2 ? isSelect : ''"
                width="103"
                height="70"
                contain
                src="@/assets/images/mini-tomato2.png"
                @click="selectImage(2)"
            ></v-img>
            <span class="selectText" v-if="selectImageNo == 2">選択中</span>
          </v-col>
          <v-col>
            <v-img
                class="toyo-image"
                v-bind:class="selectImageNo == 3 ? isSelect : ''"
                width="103"
                height="70"
                contain
                src="@/assets/images/mini-tomato3.png"
                @click="selectImage(3)"
            ></v-img>
            <span class="selectText" v-if="selectImageNo == 3">選択中</span>
          </v-col>
          <v-col>
            <v-img
                class="toyo-image"
                v-bind:class="selectImageNo == 4 ? isSelect : ''"
                width="103"
                height="70"
                contain
                src="@/assets/images/mini-tomato4.png"
                @click="selectImage(4)"
            ></v-img>
            <span class="selectText" v-if="selectImageNo == 4">選択中</span>
          </v-col>
        </v-row>
        <v-card-actions class="justify-end">
          <v-btn
              :disabled="!isImageSelect"
              color="#4CAF50"
              style="color: white; margin-bottom: 30px"
              @click="isToyoImageCheckDialog"
          >選択した画像を確認する</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="isToyoImageCheck"
        width="749">
      <v-card>
        <h2 class="dialog-title">
          選択した画像を確認して設定する
        </h2>
        <v-content class="third-dialog-content">
          <v-img
              class="toyo-image"
              width="333"
              height="254"
              contain
              src="@/assets/images/mini-tomato-check.png"
          ></v-img>
        </v-content>
        <v-card-actions class="justify-end">
          <v-btn
              color="#4CAF50"
              style="color: white; margin-bottom: 30px"
              @click="doneImageDialog"
          >画像を設定する</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "CheckData",
  methods: {
    isSelectImageDialog: function() {
      this.isSelectImageSetting = true;
      this.selectImageNo = null;
      this.searchText = '';
      this.searchDone = false;
    },
    isSelectLocalImage: function() {
      this.isSelectImageSetting = false;
    },
    isToyoImageImportDialog: function() {
      this.isSelectImageSetting = false;
      this.isToyoImageImport = true;
    },
    isToyoImageCheckDialog: function() {
      this.isToyoImageImport = false;
      this.isToyoImageCheck = true;
    },
    doneImageDialog: function() {
      this.isToyoImageCheck = false;
      this.itemList[4].image = "007_ty120501007"
    },
    selectImage: function (index) {
      this.isImageSelect = true;
      this.selectImageNo = index;
    },
    updateField: function (e){
      this.searchText = e.target.value
    },
    search: function() {
      this.searchDone = true;
    }
  },
  created: function () {
    let url = '';
    if(this.$route.query.check) {
      url = 'https://toyo-systemflyer.s3.ap-northeast-1.amazonaws.com/admin/csv/import_done_data.csv';
    } else {
      url = 'https://toyo-systemflyer.s3.ap-northeast-1.amazonaws.com/admin/csv/import_data.csv';
    }
    // CSVの取得と反映を行う
    axios.get(url).then((response) => {
      this.itemList = this.$papa.parse(response.data, {
        header: true,
        complete: function(results) {
          return results.data
        },
      }).data;
    });
  },
  data () {
    return {
      isSelectImageSetting: false,
      isToyoImageImport: false,
      isToyoImageCheck: false,
      isImageSelect: false,
      selectImageNo: null,
      searchText: '',
      searchDone: false,
      isSelect: {
        selectImage: '2px solid #FF3D00',
      },
      headers: [
        { text: '開始日', value: 'start_date', width: '120px' },
        { text: '終了日', value: 'end_date',  width: '120px' },
        { text: 'カテゴリ', value: 'category', width: '150px' },
        { text: 'メーカー・産地', value: 'manufacturer', width: '150px' },
        { text: '商品名', value: 'name', width: '150px' },
        { text: '規格', value: 'standard', width: '150px' },
        { text: '売価', value: 'selling_price', width: '100px' },
        { text: '税込', value: 'tax', width: '100px' },
        { text: 'アイコン', value: 'icon', width: '100px' },
        { text: 'タグ', value: 'tag' ,width: '100px'},
        { text: '画像設定', value: 'image', width: '80px'},
        {text: '', value: 'toyo'}
      ],
      itemList: [],
    }
  },
}
</script>

<style lang="scss" scoped>
.data-check {
  .check-sequence {
    padding-top: 30px;
    padding-left: 30px;
  }
  .check-description {
    padding-top: 43px;
    padding-left: 46px;
    padding-bottom: 64px;
  }
  .back-upload-button {
    margin-top: 41px;
    margin-left: 54px;
  }
  .done-data {
    margin-top: 45px;
    margin-left: 647px;
  }
}
.dialog-title {
  padding-top: 54px;
  text-align: center;
}
.first-dialog-description-text {
  padding-top: 37px;
}
.toyo-select-btn {
  width: 277px;
  height: 117px;
}
.second-dialog-search-area {
  padding-top: 24px;
  padding-left: 53px;
  padding-right: 89px;
}
.second-dialog-text-search {
  width: 202px;
}
.second-dialog-search-btn {
  margin-left: 17px;
  margin-top: 12px;
  color: white;
}
.second-dialog-image-area {
  padding: 32px 53px 127px;

  .toyo-image {
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.third-dialog-content {
  padding-top: 29px !important;
  padding-left: 211px;
  padding-right: 211px;
}
.selectImage {
  border: 2px solid #FF3D00;
}
.selectText {
  color: #FF3D00;
}
.image_area {
  width: 250px;
  height: 48px;
}
.image-inline {
  display: inline-block;
}
.image-text {
  padding-left: 10px;
  padding-top: 15px;
  vertical-align: top;
}
</style>
