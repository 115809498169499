<template>
  <v-container class="home">
    <h2>チラシ原稿一覧</h2>
    <v-btn
        absolute
        right
        class="green white--text ml-auto"
        elevation="2"
        to="/csvUpload"
    >新しいチラシを作成する
    </v-btn>
    <v-data-table
        :headers="headers"
        :items="itemList"
        :items-per-page="5"
        class="elevation-1 data-table"
    >
<!--      日付-->
      <template v-slot:[`item.create_date`]="{ item }">
        <div v-if="item.link_open">
          <a href="/checkData?check=true">
            {{ item.create_date }}
          </a>
        </div>
        <div v-else>
          <a >
            {{ item.create_date }}
          </a>
        </div>
      </template>
<!--      公開状態-->
      <template v-slot:[`item.open_status`]="{ item }">
        <v-btn v-if="item.open_status == '下書き'"
        outlined
        rounded
        style="border-color: #E0E0E0">
          {{item.open_status}}
        </v-btn>
        <v-btn v-else-if="item.open_status == '公開中'"
         color="#4CAF50"
         outlined
         rounded>
          {{item.open_status}}
        </v-btn>
        <v-btn v-else-if="item.open_status == '公開終了'"
        rounded>
          {{item.open_status}}
        </v-btn>
      </template>
<!--プレビュー-->
      <template v-slot:[`item.is_preview`]="{ item }">
        <v-btn
            v-model="item.is_preview"
            color="#4CAF50"
            outlined>
          プレビューを確認
        </v-btn>
      </template>
<!--ー-->
      <template v-slot:[`item.is_csv_download`]="{ item }">
        <v-btn
            v-model="item.is_csv_download"
            href="https://toyo-systemflyer.s3.ap-northeast-1.amazonaws.com/admin/csv/%E5%95%86%E5%93%81%E5%8E%9F%E7%A8%BF%E3%83%86%E3%82%99%E3%83%BC%E3%82%BF.csv"
            color="#1867C0"
            outlined>
          ダウンロード
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  created: function () {
    let url = '';
    if(this.$route.query.done) {
      url = 'https://toyo-systemflyer.s3.ap-northeast-1.amazonaws.com/admin/csv/after_flyer.csv';
    } else {
      url = 'https://toyo-systemflyer.s3.ap-northeast-1.amazonaws.com/admin/csv/before_flyer.csv';
    }
    // CSVの取得と反映を行う
    axios.get(url).then((response) => {
      this.itemList = this.$papa.parse(response.data, {
        header: true,
        complete: function(results) {
          return results.data
        },
      }).data;
          if(this.$route.query.draft) {
            this.itemList[0].open_status = '下書き'
          }
    });
  },
  data() {
    return {
      headers: [
        {
          text: '作成日',
          sortable: false,
          value: 'create_date',
        },
        {
          text: '公開状況',
          value: 'open_status',
          sortable: false,
        },
        {
          text: '商品数',
          value: 'item_count',
          sortable: false,
        },
        {
          text: 'タグ数',
          value: 'tag_count',
          sortable: false,
        },
        {
          text: 'プレビュー',
          value: 'is_preview',
          sortable: false,
        },
        {
          text: 'CSVをダウンロード',
          value: 'is_csv_download',
          sortable: false,
        },
      ],
      itemList: [],
    }
  },
}
</script>

<style lang="scss" scoped>
.home {
  .data-table {
    margin-top: 50px;
  }
}
</style>
